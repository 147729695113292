import vue from "vue";

const actions = {};
const state = {
    fibreId: null

};
const getters = {
    fibreId: state => {
        return state.fibreId;
    },
};
const mutations = {
    setFibreId(state, data) {
        vue.set(state, 'fibreId', data);
    },

};
export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
}