<script>

import mermaid from "mermaid/dist/mermaid.esm.min.mjs";
import {mapGetters} from "vuex";
import L from "leaflet";
import Swal from "sweetalert2";
import ObjectTypesEnum from "@/Enums/ObjectTypesEnum";

export default {
  name: "Tracing",
  data: function () {
    return {
      steps: [],
      lineLength: 0,
      digLocation: null,
      firstDuct: null,
      loss: null,
      loading: true,
      reverse: false,
      parseCode: null,
      config: {
        height: '200px',
        theme: "default",
        flowchartConfig: {
          width: '100%',
          height: '100%',
          useMaxWidth: true
        },
        flowchart: {
          useMaxWidth: true
        },
        useMaxWidth: true
      }
    };
  },
  watch: {
    fibreId(value) {
      if (value) {
        console.log(value);
        this.$bvModal.show('tracingModal')
      }
    }
  },
  computed: {
    ...mapGetters({
      fibreId: "Tracing/fibreId",
    })
  },
  methods: {
    editNode(nodeId) {
      alert(nodeId);
    },
    show() {
      this.$bvModal.hide(ObjectTypesEnum.getModalId(ObjectTypesEnum.OT_POLE));
      this.$bvModal.hide(ObjectTypesEnum.getModalId(ObjectTypesEnum.OT_CHAMBER));
      this.$bvModal.hide(ObjectTypesEnum.getModalId(ObjectTypesEnum.OT_STREET_CABINET));
      this.$bvModal.hide(ObjectTypesEnum.getModalId(ObjectTypesEnum.OT_SPLITTER));
      this.$bvModal.hide(ObjectTypesEnum.getModalId(ObjectTypesEnum.OT_PATCH_PANEL));
      this.$bvModal.hide(ObjectTypesEnum.getModalId(ObjectTypesEnum.OT_SPLICING_DOME));
      this.loading = true;
      mermaid.initialize({
        theme: 'forest',
        logLevel: 5,
        securityLevel: 'loose',
        flowchart: {curve: 'basis', useMaxWidth: false},
      });
      this.loadTrace().then(data => {
        const tracing = atob(data.data.tracing);
        this.loss = data.data.loss;
        this.firstDuct = data.data.start_linestring;
        this.lineLength = data.data.dbLength;
        this.load(tracing);
        this.loading = false;
      }).catch(error => {
        this.loading = false;
        console.log(error.data);
      })
    },
    loadTrace() {
      return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_NETWORKING_API_URL + '/api/trace/fibre/' + this.fibreId + '/' + Number(this.reverse), {steps: this.steps}).then(response => resolve(response)).catch(async error => {
          if (error.response.status === 307) {
            const title = error.response.data.title;
            const options = error.response.data.options;
            const {value: selectedFibre} = await Swal.fire({
              title: title,
              input: 'select',
              inputOptions: options,
              inputPlaceholder: 'Select Fibre',
              showCancelButton: true,
              inputValidator: (value) => {
                return new Promise((resolve) => {
                  resolve()
                })
              }
            })

            if (selectedFibre) {
              this.steps.push(selectedFibre)
              resolve(this.loadTrace());
            }
          } else {
            reject(error);
          }
        })
      });


    },
    load(code) {
      if (code) {
        let container = document.getElementById("mermaid");
        if (container) {
          if (container.hasAttribute('data-processed')) {
            container.removeAttribute("data-processed");
          }
          container.textContent = code;
          try {
            mermaid.run({
              nodes: [container],
            }).catch(error => {
              this.$swal('Tracing issue', 'There was an issue with tracing diagram syntax', 'warning');
            });
          } catch (error) {
            console.log(error)
          }
        }
      }
    },
    getDigLocation() {
      axios.post(process.env.VUE_APP_NETWORKING_API_URL + '/api/trace/interpolatepoint', {
        coordinates: this.firstDuct,
        distance: this.digLocation
      }).then(response => {
        const point = response.data.data.point;
        const icon = L.icon({
          iconUrl: 'https://assets.postcodegenie.com/assets/img/icons/cross.png',
          iconSize: [16, 16], // size of the icon
        });
        const marker = L.marker([point['1'], point['0']], {icon: icon}).addTo(Lmap);
        const popup = L.popup()
            .setContent("Traced to this location.");

        marker.bindPopup(popup).openPopup();
        popup.on('remove', () => {
          marker.removeFrom(Lmap)
        })
        this.$bvModal.hide('tracingModal')
        this.$bvModal.hide('SplicingDomeInfo')
      });

    },
    closed() {
      this.$store.commit('Tracing/setFibreId', null);
      this.reverse = false;
      this.steps = [];
    },
    findOffsetPoint(linestring, distance) {
      let accumulatedDistance = 0;

      for (let i = 0; i < linestring.length - 1; i++) {
        const [x1, y1] = linestring[i];
        console.log(x1, y1);
        const [x2, y2] = linestring[i + 1];

        // Calculate the length of the current segment
        const segmentLength = this.lineLen([[x1, y1], [x2, y2]])
        // const segmentLength = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
        console.log(segmentLength);
        // Check if the offset point is on this segment
        if (accumulatedDistance + segmentLength >= distance) {
          const ratio = (distance - accumulatedDistance) / segmentLength;
          const x = x1 + ratio * (x2 - x1);
          const y = y1 + ratio * (y2 - y1);
          return [x, y];
        }

        accumulatedDistance += segmentLength;
      }

      // If the given distance exceeds the length of the linestring, return null
      return null;
    },
    getPointByDistance(pnts, distance) {
      let currentLength = 0;
      let ol;
      let result;
      let self = this;
      pnts.forEach(function (point, i, points) {
        console.log(self.lineLen([points[i - 1], point]));
        ol = currentLength;
        currentLength += i ? self.lineLen([points[i - 1], point]) : 0;
        if (distance <= currentLength && distance > ol) {
          var dd = distance - ol;
          result = self.pntOnLine([points[i - 1], point], dd);
        }
      });
      return result
    },
// returns a point on a single line (two points) using distance // line=[[x0,y0],[x1,y1]]
    pntOnLine(line, distance) {
      let t = distance / this.lineLen(line)
      let xt = (1 - t) * line[0][0] + (t * line[1][0])
      let yt = (1 - t) * line[0][1] + (t * line[1][1])
      return [xt, yt]
    },
// returns the total length of a linestring (multiple points) // pnts=[[x0,y0],[x1,y1],[x2,y2],...]
    totalLen(pnts) {
      var tl = 0;
      let self = this;
      pnts.forEach(function (point, i, points) {
        tl += i ? self.lineLen([points[i - 1], point]) : 0;
      });
      return tl;
    },
// returns the length of a line (two points) // line=[[x0,y0],[x1,y1]]
    lineLen(line) {
      const polyline = L.polyline(line)
      return L.GeometryUtil.length(polyline)
    },

  },
  mounted() {
    window.mermaidClick = function (id, value) {

    }
  }
}
</script>

<template>
  <b-modal id="tracingModal" title="Fibre Tracing" @shown="show" size="xl" @hidden="closed"
           body-class="modal-body-custom">
    <div style="width: 100%; height: 100%; min-height: 200px;">
      <b-overlay :show="loading">

        <div id="mermaid" ref="mermaid" class="mermaid">

        </div>
      </b-overlay>
    </div>
    <template #modal-footer>
      <div class="d-flex w-100 justify-content-between" style="height: 30px;">
        <div class="d-flex">
          <b-input type="number" size="sm" style="font-size: 11px;" class="w-50" placeholder="Enter distance to trace"
                   v-model="digLocation"></b-input>
          <b-button variant="success" size="sm" class="pl-2 ml-2" style="font-size: 11px;" @click="getDigLocation">
            Trace
          </b-button>
        </div>
        <div class="d-flex">
          <p v-if="loss !== null" class="mr-4 text-black" style="font-size: 13px;"><strong>Loss : {{ loss }} dB</strong>
          </p>
          <b-button variant="success" size="sm" class="pl-2 ml-2" style="font-size: 11px;"
                    @click="reverse = !reverse,  show()"
                    name="check-button">
            Flip
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<style scoped>

#mermaid {
  width: 100%;
  height: 100%;
}

#mermaid-svg {
  width: 100%;
  height: 100%;
}

</style>