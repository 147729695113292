import L from "leaflet";
import Vue from "vue";

const CommonTools = {
    createPolygonFromBounds(latLngBounds, center) {
        // const latLngBounds = this.map.getBounds();
        // const center = this.map.getCenter();
        let latLongs = [];

        latLongs.push(latLngBounds.getSouthWest());//bottom left
        latLongs.push({lat: latLngBounds.getSouth(), lng: center.lng});//bottom center
        latLongs.push(latLngBounds.getSouthEast());//bottom right
        latLongs.push({lat: center.lat, lng: latLngBounds.getEast()});// center right
        latLongs.push(latLngBounds.getNorthEast());//top right
        latLongs.push({lat: latLngBounds.getNorth(), lng: center.lng});//top center
        latLongs.push(latLngBounds.getNorthWest());//top left
        latLongs.push({lat: center.lat, lng: latLngBounds.getWest()});//center left

        return JSON.stringify((new L.polygon(latLongs)).toGeoJSON());
    },
    calculateLength(lineString) {
        let result = 0;
        for (let i = 1; i < lineString.length; i++) {
            result += CommonTools.distance(lineString[i - 1][0], lineString[i - 1][1], lineString[i][0], lineString[i][1]);
        }
        return result;
    },
    distance(a, b, c, d) {
        const R = 6371000;
        let e = (c - a) * Math.PI / 180;
        b = b * Math.PI / 180;
        d = d * Math.PI / 180;
        const x = e * Math.cos((b + d) / 2);
        const y = (d - b);
        return R * Math.sqrt(x * x + y * y);
    },
    round(number, precision) {
        let pair = (number + 'e').split('e')
        let value = Math.round(pair[0] + 'e' + (+pair[1] + precision))
        pair = (value + 'e').split('e')
        return +(pair[0] + 'e' + (+pair[1] - precision))
    },
    setFlags({rootGetters, rootActions}, items, returnFirst = false, flagged = false) {
        let tmpItems = [];
        if (!flagged) {
            flagged = rootGetters['Properties/flagged'];
        }

        items.map(function (item) {
            item.isFlagged = 0;
            item.flag = '';
            const flaggedItem = flagged.filter(f => f.uprn == item.uprn);
            if (flaggedItem.length > 0) {
                item.isFlagged = 1;
                item.flag = process.env.VUE_APP_REMOTE_HOST + "/assets/img/icons/flags/flag_" + flaggedItem[0].icon + ".png";
            }

            tmpItems.push(item);
        });
        if (returnFirst && tmpItems.length > 0) {
            return tmpItems[0];
        }
        return tmpItems;
    },
    getBoundsHash(bounds) {
        const str = bounds._northEast.lat + bounds._northEast.lng + bounds._southWest.lat + bounds._southWest.lng;
        return btoa(str);
    },
    deleteConfirmation(
        title = 'Are you sure?',
        text = 'You won\'t be able to revert this!',
        ok = 'Yes, delete it!',
        cancel = 'No, Keep it!',
        icon = 'warning',
    ) {
        return new Promise((resolve, reject) => {
            Vue.swal({

                title: title,
                text: text,
                icon: icon,
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: ok,
                cancelButtonText: cancel,
                backdrop: true
            }).then(result => {
                if (result.value) {
                    return resolve(result.value);
                }
                return reject(result.value);
            })
        });
    },
    success(text, style, options = {}) {
        let config = {
            text: text,
            icon: style,
            confirmButtonText: 'Ok!',
            showCloseButton: true,
        }
        const newOptions = {...options, ...config};
        this.$swal(newOptions);
    },
    isDisplayableCatch(error) {
        if (!error instanceof Object) {
            console.log('error is not an object')
            return false;
        }
        if (error.response === undefined || !error.response instanceof Object) {
            console.log('error.response is not an object')
            return false;
        }

        if (error.response.data === undefined || !error.response.data instanceof Object) {
            console.log('error.response.data is not an object')
            return false;
        }
        if (error.response.data.error_displayed !== undefined && error.response.data.error_displayed) {
            return false;
        }
        if (error.response.data.result === undefined || !error.response.data.result instanceof String) {
            console.log('error.response.data.result is not an String')
            return false;
        }
        if (error.response.data.message === undefined || !error.response.data.result instanceof String) {
            console.log('error.response.data.message is not an String')
            return false;
        }
        const types = ['error', 'warning'];
        if (!types.includes(error.response.data.result)) {
            console.log('types doesnot include ' + error.response.data.result)
            return false;
        }
        return true;
    },
    displayCatch(error) {
        if (CommonTools.isDisplayableCatch(error)) {
            const type = error.response.data.result;
            const message = error.response.data.message;
            console.log('trying to display error');
            return Vue.swal('Error', message, type);
        }
        return false;
    }
}
export default CommonTools;