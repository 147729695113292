<template>
  <div>
    <b-modal
        id="SplicingDomeInfo" :title="title" size="lg" responsive @shown="shown()" @hidden="hidden()">
      <b-tabs>
        <!--  Splicing dome functionality    -->
        <b-tab id="PortsTab" title="Ports" @click="activeTab='ports';">
          <b-overlay rounded="sm" :show="loading">
            <div
                v-if="markerInfo!== null && markerInfo.object_type !== undefined && markerInfo.object_type !== null && markerInfo.object_type.code ==='OT_SPLICING_DOME'">
              <div v-for="(port,index) in markerInfo.object_type.port_list" :key="'PORT_I_'+index">
                <b-card class="mt-1 px-0">
                  <template #header>
                    <div class="d-flex justify-content-between">
                      <h6 class="mb-0">Port # {{ index }}</h6>
                      <div class="d-flex justify-content-between">

                        <OverlayButton cssClass="ml-2 btn btn-outline-primary btn-sm" :methodParam="index"
                                       :clickHandler="loadCables"
                                       text="Add Cable
                      "></OverlayButton>
                      </div>
                    </div>
                  </template>
                  <b-card-text>
                    <table class="table table-striped table-sm">
                      <thead>
                      <tr>
                        <th>Input</th>
                        <th class="text-right">Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-if="port.length===0">
                        <td colspan="2" class="text-center">No Input Cable</td>
                      </tr>
                      <tr v-if="port.length>0" v-for="portItem in port" :key="'PORT_'+portItem.id">
                        <td v-if="portItem.fibre_cable_id>0">C-{{ portItem.fibre_cable.sequence_id }}
                          <strong>({{ portItem.type_name }})</strong></td>
                        <td class="text-right">
                          <button v-if="portItem.fibre_cable_id>0"
                                  @click="deAttachFibreCableFromPort(portItem.port_index,portItem.fibre_cable_id)"
                                  class="btn btn-danger btn-sm">Remove Fibre Cable
                          </button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </b-card-text>


                </b-card>
              </div>
            </div>
            <div v-else>Invalid marker info</div>
          </b-overlay>
        </b-tab>
        <b-tab id="connectionsTab" title="Connections" @click="loadConnections(); activeTab='connections';">
          <b-overlay rounded="sm" :show="loadingPortConnection">
            <table class="table table-sm table-striped mt-1">
              <thead>
              <tr>
                <th>Source</th>
                <th>Destination</th>
                <th>Actions</th>
              </tr>
              <tr v-if="connections.length===0">
                <td colspan="3" class="text-center">No Connections
                  <button class="btn btn-primary btn-sm" @click="createConnectionModal">Create One
                  </button>
                </td>
              </tr>
              <tr v-for="connection in connections" :key="'connection_'+connection.id">
                <td>
                  <div class="d-flex flex-column">
                    <FibreTubeBadge :fibre="connection.sourceFibreDetails" :show-trace="true"/>
                  </div>
                </td>
                <td>
                  <div class="d-flex flex-column">
                    <FibreTubeBadge :fibre="connection.destinationFibreDetails" :show-trace="true"/>
                  </div>
                </td>
                <td>
                  <button class="btn btn-danger btn-sm" @click="disconnectConnection(connection)">Disconnect</button>
                </td>
              </tr>
              </thead>
            </table>
          </b-overlay>
        </b-tab>
        <b-tab id="objectsTab" title="Objects" @click="activeTab='objects';">
          <SplitterList :parent-object-id="markerInfo.id"></SplitterList>
        </b-tab>
      </b-tabs>
      <template #modal-footer="{ cancel, ok }">
        <b-button @click="createConnectionModal" v-if="activeTab==='connections'" variant="success">Add Connection
        </b-button>
        <b-button @click="cancel">Close</b-button>
      </template>
    </b-modal>
    <b-modal ok-title="Add to port" @hidden="clearPortConnectionActionModal"
             id="add_splicing_dome_input" :title="'Add new input for #'+selectedPort+' Port'">
      <select v-if="this.cables.length>0" v-model="selectedCable" class="form-control">
        <option :value="cable.id" v-for="cable in this.cables" :key="cable.generated_identifier">
          C-{{ cable.sequence_id }} (<strong>{{ cable.type_name }}</strong>)
        </option>
      </select>
      <template #modal-footer="{ cancel, ok }">
        <OverlayButton css-class="btn btn-primary" :click-handler="attachToPort"
                       :disabled="selectedDuctTube === null && selectedCable===null"
                       text="Add to port"></OverlayButton>
        <b-button @click="cancel">Close</b-button>
      </template>
    </b-modal>
    <Fibre2FibreConnection
        v-if="this.markerInfo.object_type!==undefined && this.markerInfo.object_type.port_list !== undefined && this.markerInfo.object_type.port_list !== null"
        :source-cables="sourceCables"
        :project-id="markerInfo.project_id"
        :object-id="markerInfo.id"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import OverlayButton from "@/components/UI/OverlayButton.vue";
import Fibre2FibreConnection from "@/components/partials/Ducting/Fibre2FibreConnection.vue";
import SplitterList from "@/components/modals/Objects/Object/SplitterList.vue";
import ObjectTypes from "@/Api/ObjectTypes";
import ObjectTypesEnum from "@/Enums/ObjectTypesEnum";
import FibreTubeBadge from "@/components/UI/FibreTubeBadge.vue";

export default {
  name: "SplicingDomeInfo",
  components: {FibreTubeBadge, SplitterList, Fibre2FibreConnection, OverlayButton},
  data: () => {
    return {
      selectedPort: null,
      loadingPortConnection: true,
      cables: [],
      selectedDuctTube: null,
      selectedCable: null,
      connections: [],
      activeTab: 'ports',
      loadingObjects: true,
      childObjects: {
        OT_SPLITTER: [],
      },
      // OLD
      modalShown: false,
      cableToPort: null,
      collapsable: [],
      objectTypes: null,
      loading: false,
      connectorMode: false,
      selected: null,
      selected2: null,
      ItuColorCoding: {
        1: 'blue',
        2: 'orange',
        3: 'green',
        4: 'brown',
        5: 'gray',
        6: 'white',
        7: 'red',
        8: 'black',
        9: 'yellow',
        10: 'violet',
        11: 'pink',
        12: 'aqua',
      },
      leftFibre: null,
      rightFibre: null
    }
  },
  computed: {
    currentUser: {
      get() {
        return this.$store.state.Profile.user;
      }
    },
    ...mapGetters({
      markerInfo: 'ObjectMarker/makerInfo',
    }),
    objectTypesSplitter: {
      get() {
        return this.childObjects[ObjectTypesEnum.OT_SPLITTER];
      }
    },
    title: {
      get() {

        if (this.markerInfo.object_type != undefined) {
          return 'O-' + this.markerInfo.sequence_id + ' (' + this.markerInfo.object_type.name + ')';
        } else {
          if (this.loading) {
            return;
          }
          return '';
        }
      }
    },
    sourceCables: {
      get() {
        let cables = [];
        if (this.markerInfo.object_type === undefined || this.markerInfo.object_type.port_list == undefined || this.markerInfo.object_type.port_list === null) {
          return cables;
        }
        for (let ports of Object.values(this.markerInfo.object_type.port_list)) {
          for (let port of ports) {
            let item = {};
            item.project_id = this.markerInfo.project_id;
            item.name = 'C-' + port.fibre_cable.sequence_id;
            item.type = 'fibre_cable';
            item.id = port.fibre_cable.id;
            item.data = port.fibre_cable;
            item.cable_type = port.type_name;
            cables.push(item);

          }
        }
        return cables
      }
    }
  },
  methods: {
    disconnectConnection(connection) {
      this.loadingPortConnection = true;
      axios.delete(process.env.VUE_APP_NETWORKING_API_URL + '/api/fibre_to_fibre_connection/' + connection.id).then(() => {
        this.loadConnections();
      }).finally(() => {
        this.loadingPortConnection = false;
      })
    },
    createConnectionModal() {
      this.$bvModal.show('Fibre2FibreConnection')
    },
    loadChildObjects() {
      this.loadingObjects = true;
      ObjectTypes.getChilds(this.markerInfo.id).then(response => {
        this.childObjects = response.data.data;
      }).finally(() => {
        this.loadingObjects = false;
      });
    },

    refreshMarkerInfo() {
      this.loading = true;
      axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/info/' + this.markerInfo.id).then(response => {
        this.$store.commit('ObjectMarker/setMakerInfo', response.data.data);

      }).finally(() => {
        this.loading = false;
      })
    },
    loadCables(index) {
      return new Promise((success, error) => {
        this.selectedPort = index;
        this.loadingPortConnection = true;
        axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/' + this.markerInfo.id + '/free_cables_on_location').then(response => {
          if (response.data.data.length === 0) {
            this.$swal('', 'There are no fibre cables near this splicing dome', 'info');
            return success();
          }
          this.cables = response.data.data
          this.$bvModal.show('add_splicing_dome_input')
          success();
        }).catch((e) => {
          error(e)
        });
      });
    },
    loadConnections() {
      this.loadingPortConnection = true;
      axios.get(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/ot_splicing_dome/connections/' + this.markerInfo.id).then(response => {
        this.connections = response.data.data;
      }).finally(() => {
        this.loadingPortConnection = false;
      });
    },
    clearPortConnectionActionModal() {
      this.selectedPort = null;
      this.selectedCable = null;
      this.selectedDuctTube = null;
    },
    attachToPort() {
      let type = '';
      let obj = 0;
      if (this.selectedCable > 0) {
        type = 'cable';
        obj = this.selectedCable;
      } else {
        type = 'duct_tube'
        obj = this.selectedDuctTube;
      }
      return new Promise((success, error) => {
        axios.put(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/' + this.markerInfo.id + '/ot_splicing_dome/port/' + this.selectedPort + '/' + type + '/' + obj).then(response => {
          success();
          this.$bvModal.hide('add_splicing_dome_input')
          this.refreshMarkerInfo();
        }).catch((e) => {
          error(e)
        });
      });
    },
    deAttachFibreCableFromPort(portIndex, objectId) {

      this.loading = true;
      return new Promise((success, error) => {
        axios.delete(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/' + this.markerInfo.id + '/ot_splicing_dome/port/' + portIndex + '/cable/' + objectId).then(response => {
          success();
          this.refreshMarkerInfo();
        }).catch((e) => {
          this.loading = false;
        })
      });
    },
    shown() {
      this.modalShown = true;
      this.loading = false;
    },
    hidden() {
      this.modalShown = false;
      this.loading = false;
    },

  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId === 'Fibre2FibreConnection') {
        this.loadConnections();
      }
    })
  }

}
</script>

<style scoped>

.line {
  width: 2em;
  height: 8px;
  background-color: #4b4b4d;
}

.highlight {
  background-color: red;
}

.horizontalLine {
  height: 1px;
  width: 49%;
  color: #fff;
  float: left;
  display: block;
}

.verticalLine {
  display: block;
  float: left;
  height: 1px;
  width: 1px;
}

</style>