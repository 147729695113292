<template>
  <div>
    <div v-if="Utils.isMobile()" class="pl-2">
      <div class="position-absolute w-100 justify-content-around d-flex mobile-navigation pt-2">
        <MapSelector v-if="isLoggedIn && mapIsLoaded"></MapSelector>
        <SearchComponent v-if="isLoggedIn"></SearchComponent>
        <HeaderMenu></HeaderMenu>
      </div>
    </div>
    <div class="d-flex justify-content-end" id="top_bar" v-if="!Utils.isMobile()">


      <MapSelector v-if="isLoggedIn && mapIsLoaded"></MapSelector>
      <SearchComponent v-if="isLoggedIn"></SearchComponent>

      <b-button v-b-modal.advanced_search class="btn py-0 bg-white overlay-object text-dark ml-2"
        v-if="!Utils.isMobile()">
        <b-icon-tools></b-icon-tools>
      </b-button>
      <b-navbar-nav>
        <HeaderMenu></HeaderMenu>
      </b-navbar-nav>


    </div>
    <AdvancedSearch></AdvancedSearch>
    <Loading v-if="isUserLoading"></Loading>
    <Profile v-if="isLoggedIn"></Profile>
    <Projects v-if="isLoggedIn"></Projects>
    <DuctTypeList v-if="isLoggedIn"></DuctTypeList>
    <DuctInfoCreate v-if="isLoggedIn"></DuctInfoCreate>
    <DuctInfo v-if="isLoggedIn"></DuctInfo>
    <SplitterInfo></SplitterInfo>
    <StreetCabinetInfo v-if="isLoggedIn"></StreetCabinetInfo>
    <PatchPanelInfo v-if="isLoggedIn"></PatchPanelInfo>
    <ChamberInfo v-if="isLoggedIn"></ChamberInfo>
    <SplicingDomeInfo v-if="isLoggedIn"></SplicingDomeInfo>
    <PoleInfo></PoleInfo>
    <DuctChamberConnection v-if="isLoggedIn"></DuctChamberConnection>
    <Opportunities v-if="isLoggedIn"></Opportunities>
    <ObjectTypeList v-if="isLoggedIn"></ObjectTypeList>
    <CableTypeList v-if="isLoggedIn"></CableTypeList>
    <AddressMatching v-if="isLoggedIn"></AddressMatching>
    <PostcodeInformation v-if="isLoggedIn"></PostcodeInformation>
    <PropertyList v-if="isLoggedIn"></PropertyList>
    <BulkFlagChange v-if="isLoggedIn"></BulkFlagChange>
    <ElevationCalculations v-if="isLoggedIn"></ElevationCalculations>
    <ImportPolygons v-if="isLoggedIn"></ImportPolygons>
    <SitesList v-if="isLoggedIn"></SitesList>
    <GeoTagPhotoList v-if="isLoggedIn"></GeoTagPhotoList>
    <SaveLocations v-if="isLoggedIn"></SaveLocations>
    <OpenreachPia v-if="isLoggedIn"></OpenreachPia>
    <!--    Admin-->
    <UsersList v-if="isAdmin" />
    <Reports v-if="isAdmin" />
    <Settings v-if="isMasterUser" />
    <Tracing />
    <SubAccounts />
    <ExportPropertiesHistory />
  </div>
</template>

<script>
import MapSelector from './MapSelector.vue'
import StreetCabinetInfo from "../modals/Objects/Info/StreetCabinetInfo";
import PoleInfo from "../modals/Objects/Info/PoleInfo";
import AddressMatching from "@/components/modals/AddressMatching";
import AdvancedSearch from "@/components/modals/AdvancedSearch";
import Login from "@/components/modals/Auth/Login";
import Profile from "@/components/modals/Profile";
import Projects from "@/components/modals/Project/Projects";
import DuctTypeList from "@/components/modals/Duct/Types/DuctTypeList";
import Opportunities from "@/components/modals/Opportunities/Opportunities";
import ObjectTypeList from "@/components/modals/Objects/Types/ObjectTypeList";
import SearchComponent from "@/components/partials/SearchComponent";
import BulkFlagChange from "@/components/modals/BulkFlagChange";
import ElevationCalculations from "@/components/modals/ElevationCalculations";
import DuctInfoCreate from "@/components/modals/Duct/Info/DuctInfoCreate";
import DuctInfo from "@/components/modals/Duct/Info/DuctInfo";
import CableTypeList from "@/components/modals/Cable/Type/CableTypeList";
import DuctChamberConnection from "@/components/modals/Networking/DuctChamberConnection";
import ImportPolygons from "@/components/modals/ImportPolygons";
import PostcodeInformation from "../modals/PostcodeInformation";
import SitesList from "@/components/modals/Site/SitesList";
import PropertyList from "@/components/modals/PropertyList";
import LocationsPhotoList from "../modals/LocationPhoto/LocationsPhotoList";
import SplicingDomeInfo from "../modals/Objects/Info/SplicingDomeInfo";
import GeoTagPhotoList from "../modals/GeoTagPhotoList";
import SaveLocations from "../modals/SaveLocations";
import PatchPanelInfo from "../modals/Objects/Info/PatchPanelInfo";
import ChamberInfo from "../modals/Objects/Info/ChamberInfo";
import { mapGetters } from "vuex";
import OpenreachPia from "@/components/Modules/OpenreachPIA/components/modals/OpenreachPia";
import Loading from "@/components/modals/Loading.vue";
import UsersList from "@/Admin/Modules/UserManagement/UsersList.vue";
import Reports from "@/Admin/Modules/MobileCoverage/Modals/Reports.vue";
import Tracing from "@/components/modals/Fibre/Tracing.vue";
import Settings from "@/components/modals/Settings.vue";
import SubAccounts from "@/Shared/Components/Modals/SubAccounts.vue";
import SplitterInfo from "@/components/modals/Objects/Info/SplitterInfo.vue";
import Utils from "@/assets/js/libs/Utils";
import HeaderMenu from "@/components/partials/HeaderMenu.vue";
import ExportPropertiesHistory from "@/components/modals/Properties/Export/ExportPropertiesHistory.vue"
export default {

  components: {
    HeaderMenu,
    SplitterInfo,
    Settings,
    Tracing,
    Reports,
    Loading,
    SplicingDomeInfo,
    ChamberInfo,
    PoleInfo,
    OpenreachPia,
    SaveLocations,
    LocationsPhotoList,
    GeoTagPhotoList,
    PropertyList,
    SitesList,
    ImportPolygons,
    DuctChamberConnection,
    StreetCabinetInfo,
    PatchPanelInfo,
    CableTypeList,
    ElevationCalculations,
    BulkFlagChange,
    SearchComponent,
    ObjectTypeList,
    DuctTypeList,
    Opportunities,
    Projects,
    Profile,
    Login,
    MapSelector,
    AdvancedSearch,
    AddressMatching,
    PostcodeInformation,
    DuctInfoCreate,
    DuctInfo,
    UsersList,
    SubAccounts,
    ExportPropertiesHistory,
  },
  computed: {
    Utils() {
      return Utils
    },

    isMasterUser: {
      get() {
        return this.$store.state.Profile.user.parent_user_id === null;
      }
    },
    ...mapGetters({
      isLoggedIn: 'Auth/isLoggedIn',
      isUserLoading: 'Auth/isUserLoading',
      mapIsLoaded: 'Maps/mapIsLoaded',
      isAdmin: 'Auth/isAdmin'
    }),
  },


}
</script>

<style>
#top_bar {
  z-index: 1000;
  width: 100%;
  position: absolute;
  margin-top: 10px;
  right: 0;
}

/*@media (min-width: 576px) {*/
.navbar-expand-sm {
  flex-flow: row nowrap;
  justify-content: flex-start;
  /*}*/
}

.mobile-navigation {
  z-index: 1000;
}
</style>
