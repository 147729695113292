<template>
  <div>
    <b-modal id="PatchPanelInfo" :title="title" size="xl" responsive @shown="getConnections()">
      <b-tabs>
        <b-tab title="Ports" @click="activeTab='ports';">
          <b-overlay rounded="sm" :show="loading">
            <table class="table table-responsive" style="padding-top:100px;" v-if="connections">
              <tr style="background-color: #b4b0b5" v-for="ports in connections.port_connections">
                <th v-for="port in ports" :key="port.port">
                  <div v-if="port.fibre" style="min-width: 65px; position: relative;">
                    <b-button pill size="sm" @click="showTracing(port.fibre)"
                              v-b-tooltip.hover.top.html="getFibreTooltip(port.fibre)"
                              class="bg-success" style="min-width: 62px;">
                      <span><i class='fas fa-ethernet'></i> - {{ port.port }}</span>
                    </b-button>
                    <span class="disconnect-port badge rounded-pill bg-danger"
                          @click="disconnectPort(port)">X</span>

                  </div>
                  <b-button class="d-flex" @click="portConnections(port)" pill size="sm" style="min-width: 65px;"
                            v-else>
                    <span class="mx-auto my-auto"><i class='fas fa-ethernet'></i> - {{ port.port }}</span>
                    <b-checkbox @change="addPortToBulkQueue(port)" class="my-auto ml-2"
                                v-if="isInBulkMode"></b-checkbox>
                  </b-button>
                </th>
              </tr>
            </table>
            <b-button v-if="!isInBulkMode"
                      variant="primary" class="float-right"
                      @click="isInBulkMode=!isInBulkMode">Bulk connect
            </b-button>
            <b-button v-if="isInBulkMode"
                      variant="secondary" class="float-right ml-3"
                      @click="isInBulkMode=false;bulkPortQueue=[]">Cancel
            </b-button>
            <b-button @click="bulkNext" v-if="isInBulkMode && bulkPortQueue.length>0"
                      variant="primary" class="float-right">Next
            </b-button>
          </b-overlay>
        </b-tab>
        <b-tab id="patchPanleObjectsTab" title="Objects" @click="activeTab='objects';">
          <SplitterList :parent-object-id="markerInfo.id" :loadParentObjectFibres="true"></SplitterList>
        </b-tab>
      </b-tabs>
      <b-modal id="cable_to_port" title="Cable Connections" size="lg" @ok="storeFibreConnectionBulk">
        <b-overlay rounded="sm" :show="cableToPortOverlay">
          <div class="col-12" v-if="connections">

            <div class="row" v-if="connections['connectable_cables'].length > 0">
              <b-form-group class="w-100" label="Select Cable">
                <b-form-select
                    v-model="cableToPort"
                    :options="connections['connectable_cables']"
                    class="form-control w-100"
                    text-field="cable_name"
                    disabled-field="notEnabled"
                ></b-form-select>
              </b-form-group>
            </div>
            <div v-else class="text-center text-muted pt-5">
              There is no fibre available for connection
            </div>
            <div class="col-12 mt-5">
              <b-table-simple hover fixed responsive bordered>
                <b-tbody id="1-body" v-if="connections && cableToPort != null">
                  <b-tr
                      v-if="module.filter(item=>item.attached_to_port===null).length>0"
                      v-for="(module,tubeIndex) in connections['fibres'][cableToPort]['value']" :key="tubeIndex">
                    <b-td :style="{backgroundColor: ItuColorCoding[tubeIndex]}" style="text-align: center">
                      <b-button v-b-toggle="'1-fibres-' + tubeIndex" variant="primary">
                        <div class="d-flex flex-column">
                          <div>Tube {{ tubeIndex }}</div>
                        </div>
                      </b-button>
                      <div>
                        <b-collapse v-model="collapsable['1-fibres-' + tubeIndex]" v-bind:id="'1-fibres-' + tubeIndex"
                                    class="mt-2">
                          <b-card v-bind:id="'1-tube-' + tubeIndex">
                            <b-list-group v-for="fibre in module" :key="fibre.fibre_id">
                              <b-list-group-item :style="'background-color: '+fibre.fibre_color"
                                                 v-if="!isInBulkMode && fibre.attached_to_port===null"
                                                 class="cursor-pointer"
                                                 @click="storeFibreConnection(fibre.fibre_id)"
                                                 v-bind:id="'1-fibre-' + fibre.fibre_id">
                                <div class="d-flex flex-column">
                                  <div>Connect Fibre {{ fibre.fibre_number }}</div>
                                  <div>{{ fibre.generated_identifier }}</div>
                                </div>


                              </b-list-group-item>
                              <b-list-group-item :style="'background-color: '+fibre.fibre_color" v-if="isInBulkMode"
                                                 class="cursor-pointer"
                                                 @click="pushToFibreQueue(fibre)"
                                                 v-bind:id="'1-fibre-' + fibre.fibre_id">
                                <span class="fas fa-check-circle" v-if="isInFibreQueue(fibre)"></span>
                                <div class="d-flex flex-column">
                                  <div>Connect Fibre {{ fibre.fibre_number }}</div>
                                  <div>{{ fibre.generated_identifier }}</div>
                                </div>
                              </b-list-group-item>
                            </b-list-group>
                          </b-card>
                        </b-collapse>
                      </div>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </div>
            <div class="row">
              <div class="d-flex w-100 justify-content-start" v-for="(port,index) in bulkPortQueue">
                <div class="col-3">Port {{ port.port }}</div>
                <div class="col-2">
                  <span class="fas fa-arrows-alt-h text-success" v-if="fibreQueue[index]"></span>
                  <span class="fas fa-times-circle text-danger" v-if="!fibreQueue[index]"></span>
                </div>
                <div v-if="fibreQueue[index]">Fibre {{ fibreQueue[index].fibre_number }}
                  ({{ fibreQueue[index].generated_identifier }})
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </b-modal>
    </b-modal>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import ObjectTypes from "@/Api/ObjectTypes";
import SplitterList from "@/components/modals/Objects/Object/SplitterList.vue";
import ObjectTypesEnum from "@/Enums/ObjectTypesEnum";
import PatchPanel from "@/Api/Objects/PatchPanel";

export default {
  name: "PatchPanelInfo",
  components: {SplitterList},
  data: () => {
    return {
      api: new PatchPanel(),
      isInBulkMode: false,
      bulkPortQueue: [],
      fibreQueue: [],
      activeTab: 'ports',
      loadingObjects: true,
      childObjects: {
        OT_SPLITTER: [],
      },
      selected: null,
      selected2: null,
      ItuColorCoding: {
        1: 'blue',
        2: 'orange',
        3: 'green',
        4: 'brown',
        5: 'gray',
        6: 'white',
        7: 'red',
        8: 'black',
        9: 'yellow',
        10: 'violet',
        11: 'pink',
        12: 'aqua',
      },
      collapsable: [],
      portId: null,
      cableToPort: null,
      objectTypes: null,
      /**
       * @type PatchPanelData
       */
      connections: null,
      loading: true,
      cableToPortOverlay: false,
    }
  },
  computed: {
    currentUser: {
      get() {
        return this.$store.state.Profile.user;
      }
    },
    ...mapGetters({
      markerInfo: 'ObjectMarker/makerInfo',
    }),
    title: {
      get() {
        if (this.markerInfo && this.markerInfo.object_type != undefined) {
          return this.markerInfo.generated_identifier + ' (' + this.markerInfo.object_type.name + ')';
        } else {
          if (this.loading) {
            return;
          }
          return '';
        }
      }
    },
    objectTypesSplitter: {
      get() {
        return this.childObjects[ObjectTypesEnum.OT_SPLITTER];
      }
    }
  },
  methods: {
    ...mapMutations({
      setFibreId: "Tracing/setFibreId"
    }),
    showTracing(item) {
      this.setFibreId(item.id);
    },

    getFibreTooltip(item) {
      return '<div class="d-flex flex-column">' +
          '<div class="d-flex">' +
          '<span class="p-1 border border-white rounded">C- ' + item.cable.sequence_id + '</span>' +
          '<span class="p-1">-</span>' +
          '<span class="p-1 border border-white rounded" style="background-color:' + item.tube_color + '">Tube ' + item.tube_number + '</span>' +
          '<span class="p-1">-</span>' +
          '<span class="p-1 border border-white rounded" style="background-color:' + item.fibre_color + '">Fibre ' + item.fibre_number + '</span>' +
          '</div>';
    },
    bulkNext() {
      this.$bvModal.show('cable_to_port');
    },
    pushToFibreQueue(fibre) {

      if (!this.isInFibreQueue(fibre)) {
        if (this.bulkPortQueue.length === this.fibreQueue.length) {
          this.$swal('Connections are full', 'You have already add fibres to all ports', 'warning');
          return;
        }
        this.fibreQueue.push(fibre);
      } else {
        const indexOfObject = this.fibreQueue.findIndex(object => {
          return object.fibre_id === fibre.fibre_id;
        });
        if (indexOfObject != null) {
          this.fibreQueue.splice(indexOfObject, 1);
        }
      }
    },
    isInFibreQueue(fibre) {
      return this.fibreQueue.some(function (elem) {
        return elem.fibre_id === fibre.fibre_id;
      });
    },
    addPortToBulkQueue(item) {
      if (!this.isInBulkQueue(item)) {
        this.bulkPortQueue.push(item);
      } else {
        const indexOfObject = this.bulkPortQueue.findIndex(object => {
          return object.port_id === item.port_id;
        });
        if (indexOfObject != null) {
          this.bulkPortQueue.splice(indexOfObject, 1);
        }
      }
    },
    isInBulkQueue(item) {
      return this.bulkPortQueue.some(function (elem) {
        return elem.port_id === item.port_id;
      });
    },
    portConnections(item) {
      if (!this.isInBulkMode) {
        this.portId = item.port_id
        this.$bvModal.show('cable_to_port');
      }
    },
    storeFibreConnection(fibre) {
      if (!fibre) {
        return;
      }
      const data = {
        'port_id': this.portId,
        'fibre_id': fibre
      };
      this.api.storeFibreConnection(data).then(() => {
        this.getConnections()
        this.cableToPort = null
        this.portId = null
      })
      this.$bvModal.hide('cable_to_port');
    },
    storeFibreConnectionBulk() {
      if (!this.isInBulkMode) {
        return;
      }
      if (this.bulkPortQueue.length === 0 || this.bulkPortQueue.length !== this.fibreQueue.length) {
        this.$swal('Error', 'There should be same amount of fibres as ports', 'danger')
        return;
      }
      let connections = [];

      for (let i = 0; i < this.bulkPortQueue.length; i++) {
        const newObj = {
          port_id: this.bulkPortQueue[i].port_id,
          fibre_id: this.fibreQueue[i].fibre_id
        };
        connections.push(newObj);
      }
      this.api.storeFibreConnectionBulk({connections: connections}).then(() => {
        this.getConnections()
        this.cableToPort = null
        this.portId = null
        this.fibreQueue = [];
        this.bulkPortQueue = [];
        this.isInBulkMode = false;
      })
      this.$bvModal.hide('cable_to_port');
    },
    getConnections() {
      this.loading = true;
      this.connections = null;
      this.api.getConnections(this.markerInfo.id).then(response => {
        this.connections = response.data.data;

      }).finally(() => {
        this.loading = false;
      })
    },
    disconnectPort(item) {
      this.loading = true;
      this.api.disconnectPort(this.markerInfo.id, item.port_id).then(() => {
        this.getConnections()
        this.cableToPort = null
        this.portId = null
      }).finally(() => {
        this.loading = false;
      })
    },
    loadChildObjects() {
      this.loadingObjects = true;
      ObjectTypes.getChilds(this.markerInfo.id).then(response => {
        this.childObjects = response.data.data;
      }).finally(() => {
        this.loadingObjects = false;
      });
    },
  }
}
</script>

<style scoped>
.table-margin-top-50 {
  margin-top: 50px;
}

.disconnect-port {
  position: absolute;
  right: -2px;
  top: -4px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  color: white;
  border: 1px solid white;
}
</style>