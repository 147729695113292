<template>
  <div>
    <b-modal id="address_matching_list" size="xl" centered title="Address Matching List">
      <template #modal-footer>
        <b-col>
          <b-pagination v-if="totalRows > 0 && totalRows > perPage" aria-controls="my-table" v-model="currentPage"
                        :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
        </b-col>
      </template>
      <template #modal-title>
        Address Matching
        <b-button class="right text-right ml-5" size="sm" variant="outline-success" v-b-modal.address_matching>Upload
          new file
        </b-button>

        <b-button class="right text-right ml-5" size="sm" variant="outline-success" @click="refreshMatchingTable">
          <b-icon icon="arrow-counterclockwise" animation="spin-reverse" font-scale="1"></b-icon>
        </b-button>
      </template>
      <b-overlay :show="showOverlay" rounded="sm">
        <h4 class="text-center text-secondary" v-if="totalRows === 0">No Records</h4>

        <b-table v-show="totalRows > 0" id="my-table" ref="my-table" :items="myProvider" :fields="fields"
                 :current-page="currentPage" :per-page="perPage" :no-provider-paging="true">


          <template #cell(fileName)="row">
            <a :href="sourceDownloadLink(row.item)" class="btn btn-success btn-sm">
              {{ row.item.file_name }}
            </a>
          </template>
          <template #cell(date)="row">
            {{ row.item.startDate }}
          </template>
          <template #cell(status)="row">
            <span class="badge badge-danger" v-if="row.item.status === 'finished_fail'" v-b-tooltip.hover
                  :title="row.item.comment">Failed</span>
            <span class="badge badge-light" v-if="row.item.status === 'new'">New</span>
            <span class="badge badge-info" v-if="row.item.status === 'in_progress'">Processing</span>
            <span class="badge badge-success" v-if="row.item.status === 'finished_success'">Succeed</span>
          </template>
          <template #cell(action)="row">
            <a :href="resultDownloadLink(row.item)" v-if="row.item.status === 'finished_success'"
               class="btn btn-success btn-sm">
              Download
            </a>
            <div @click="deleteRecord(row.item)" v-if="row.item.status !== 'in_progress' && row.item.status !== 'new'"
                 class="btn btn-danger btn-sm ml-1">
              Delete
            </div>
          </template>
          <template #cell(manual)="row">
            <div
                v-if="row.item.rows_count > (row.item.succeed + row.item.skipped) && row.item.status === 'finished_success'"
                @click="getUnmatched(row.item.id)" class="btn btn-info btn-sm">
              Complete Manually
            </div>
            <div v-if="row.item.skipped > 0 && row.item.status === 'finished_success'"
                 @click="getUnmatched(row.item.id, showAllFields, true)" class="btn btn-info btn-sm">
              Complete Skipped
            </div>
          </template>
        </b-table>

      </b-overlay>
    </b-modal>
    <b-modal @close="modalClosed()" @cancel="modalClosed()" size="lg" id="address_matching" centered
             title="Address Matching" @ok="uploadMatchingFile" ok-title="Upload">
      <b-overlay :show="showOverlay" rounded="sm">
        <b-form-group v-if="this.columns.length === 0" id="address_matching-group" label="File for matching"
                      label-for="address_matching-group">
          <b-form-file v-model="matchingFile" accept="text/csv, .xls, .xlsx" :state="Boolean(matchingFile)"
                       placeholder="Choose a file or drop it here..."
                       drop-placeholder="Drop file here..."></b-form-file>

        </b-form-group>
        <b-form-group v-if="columns.length > 0">
          <b-form-checkbox id="checkbox-1" v-model="isHeader" name="checkbox-1" value="1" unchecked-value="0" switch>
            First line contains column headers
          </b-form-checkbox>
        </b-form-group>
        <b-form-group v-if="columns.length > 0">
          <b-form-checkbox id="checkbox-3" v-model="includeLatLng" name="checkbox-3" value="1" unchecked-value="0"
                           switch>
            Include Latitude and Longitude
          </b-form-checkbox>
        </b-form-group>
        <b-form-group v-if="columns.length > 0">
          <b-form-checkbox id="checkbox-2" v-model="condenseAddresses" name="checkbox-2" value="1" unchecked-value="0"
                           switch>
            Condense Addresses
          </b-form-checkbox>
        </b-form-group>
        <b-form-group v-if="columns.length > 0">
          <b-form-checkbox id="checkbox-4" v-model="includeProviderData" name="checkbox-4" value="1" unchecked-value="0"
                           switch>
            Include Provider Data
          </b-form-checkbox>
        </b-form-group>
        <b-form-group v-if="columns.length > 0">
          <b-form-checkbox id="checkbox-5" v-model="includeNetworkCoverageData" name="checkbox-4" value="1"
                           unchecked-value="0" switch>
            Include Network Coverage Data
          </b-form-checkbox>
        </b-form-group>
        <b-form-group v-if="columns.length > 0" description="UPRN">
          <div class="row col-12">
            <b-form-tags class="col-6" :class="{ 'is-invalid': inputs.uprn.hasError }" input-id="tags-pills"
                         tag-variant="primary" placeholder="" tag-pills size="lg" v-model="inputs.uprn.selectedColumns"
                         :tag-validator="tagValidator"></b-form-tags>
            <div class="col-6">
              <b-form-select v-model="inputs.uprn.selected" :options="availableColumns"></b-form-select>
            </div>
          </div>
        </b-form-group>
        <b-form-group v-if="columns.length > 0" description="Company">
          <div class="row col-12">
            <b-form-tags class="col-6" :class="{ 'is-invalid': inputs.company.hasError }" input-id="tags-pills"
                         tag-variant="primary" placeholder="" tag-pills size="lg"
                         v-model="inputs.company.selectedColumns"
                         :tag-validator="tagValidator"></b-form-tags>
            <div class="col-6">
              <b-form-select v-model="inputs.company.selected" :options="availableColumns"></b-form-select>
            </div>
          </div>
        </b-form-group>
        <b-form-group v-if="columns.length > 0" description="Property Name / Number / Street *">
          <div class="row col-12">
            <b-form-tags class="col-6" :class="{ 'is-invalid': inputs.property.hasError }" input-id="tags-pills"
                         tag-variant="primary" placeholder="" tag-pills size="lg"
                         v-model="inputs.property.selectedColumns"
                         :tag-validator="tagValidator"></b-form-tags>
            <div class="col-6">
              <b-form-select v-model="inputs.property.selected" :options="availableColumns"></b-form-select>
            </div>
          </div>
        </b-form-group>
        <b-form-group v-if="columns.length > 0" description="Postcode *">
          <div class="row col-12">
            <b-form-tags class="col-6" input-id="tags-pills" tag-variant="primary"
                         :class="{ 'is-invalid': inputs.postcode.hasError }" placeholder="" tag-pills size="lg"
                         v-model="inputs.postcode.selectedColumns" :tag-validator="tagValidator"></b-form-tags>
            <div class="col-6">
              <b-form-select v-model="inputs.postcode.selected" :options="availableColumns"></b-form-select>
            </div>
          </div>
        </b-form-group>
        <b-form-group v-if="columns.length > 0" description="Latitude">
          <div class="row col-12">
            <b-form-tags class="col-6" :class="{ 'is-invalid': inputs.latitude.hasError }" input-id="tags-pills"
                         tag-variant="primary" placeholder="" tag-pills size="lg"
                         v-model="inputs.latitude.selectedColumns"
                         :tag-validator="tagValidator"></b-form-tags>
            <div class="col-6">
              <b-form-select v-model="inputs.latitude.selected" :options="availableColumns"></b-form-select>
            </div>
          </div>
        </b-form-group>
        <b-form-group v-if="columns.length > 0" description="Longitude">
          <div class="row col-12">
            <b-form-tags class="col-6" :class="{ 'is-invalid': inputs.longitude.hasError }" input-id="tags-pills"
                         tag-variant="primary" placeholder="" tag-pills size="lg"
                         v-model="inputs.longitude.selectedColumns"
                         :tag-validator="tagValidator"></b-form-tags>
            <div class="col-6">
              <b-form-select v-model="inputs.longitude.selected" :options="availableColumns"></b-form-select>
            </div>
          </div>
        </b-form-group>
      </b-overlay>
    </b-modal>
    <b-modal size="lg" id="address_unmatched" centered ok-title="Done">
      <template #modal-title>
        Manually Matching : Row#{{ currentRow }}
      </template>
      <div class="row">
        <div class="col-6">
          <div class="row">
            <b-button v-if="!showAllFields" class="right text-right mb-1 ml-3" size="sm" variant="outline-success"
                      @click="getUnmatched(currentUnMatch, true)">Show All Fields
            </b-button>
            <b-button v-else="showAllFields" class="right text-right mb-1 ml-3" size="sm" variant="outline-success"
                      @click="getUnmatched(currentUnMatch, false)">Show Filtered
            </b-button>
          </div>
          <b-table :fields="unmatchedFields" :items="unmatchedLine" ref="unmatched_table" show-empty stacked
                   id="unmatched_table">
          </b-table>
        </div>
        <div class="col-6 border-left">
          <div class="row mb-1">
            <div class="col-6">
              <b-form-group>
                <b-form-input size="sm" v-model="unmatchedPostcode" name="checkbox-3" placeholder="Postcode"/>
              </b-form-group>
            </div>
            <div class="col-6">
              <b-button class="right text-right ml-1" size="sm" variant="outline-success"
                        @click="getPostCode(unmatchedPostcode)">Search
              </b-button>
            </div>
          </div>
          <ul class="postcode_addresses">
            <li v-for="(address, index) in postcodeAddresses"
                :class="{ address_list_item_active: selectedAddressIndex === index }" :key="address.key"
                @click="setUprn(address.uprn, index)">
              <span><strong>{{ address.display_text_normal }} </strong>{{
                  address.display_text_bold
                }}</span>
            </li>
          </ul>
        </div>
      </div>
      <template #modal-footer>
        <b-button v-if="showSkipButton" class="right text-right ml-1" size="sm" variant="outline-info" @click="skip()">
          Skip
        </b-button>
        <b-button v-if="showNextButton" class="right text-right ml-1" size="sm" variant="outline-success"
                  @click="next()">Next
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import XLSX from 'xlsx';

export default {

  components: {},
  name: "AddressMatching",
  watch: {
    matchingFile: function (value) {
      if (value) {
        const self = this;
        const reader = new FileReader();
        if ('text/csv' === value.type) {

          reader.onload = function (e) {
            self.columns = e.target.result.split("\n")[0].split(",");
            self.hFooter = false;
          };
          reader.readAsText(value)
        } else {

          reader.onload = function (e) {
            /* read workbook */
            const workbook = XLSX.read(e.target.result, {type: 'binary'});
            const ws = workbook.Sheets[workbook.SheetNames[0]];
            const file_csv = XLSX.utils.sheet_to_csv(ws)
            const file = new File([file_csv], value.name + '.csv', {
              type: 'text/csv'
            })
            self.matchingFile = file;
          };

          reader.readAsBinaryString(value);

        }

      }
    },
    columns: function () {
      this.inputs.uprn.columns = this.columns;
    },
    uprnColumns: function (value) {
      this.inputs.uprn.columns = this.columns.filter(x => !value.includes(x));
      if (value.length < this.inputs.uprn.minItems || value.length > this.inputs.uprn.maxItems) {
        this.inputs.uprn.hasError = true;
        this.inputs.company.minItems = 1;
      } else {
        this.inputs.uprn.hasError = false;
        this.inputs.company.hasError = false;
        this.inputs.postcode.hasError = false;
        this.inputs.property.hasError = false;
        this.inputs.latitude.hasError = false;
        this.inputs.longitude.hasError = false;
        this.inputs.company.minItems = 0;
        this.inputs.postcode.minItems = 0;
        this.inputs.property.minItems = 0;
        this.inputs.latitude.minItems = 0;
        this.inputs.longitude.minItems = 0;
      }
    },
    propertyColumns: function (value) {
      this.inputs.company.columns = this.columns.filter(x => !value.includes(x));
      if (value.length < this.inputs.property.minItems || value.length > this.inputs.property.maxItems) {
        this.inputs.property.hasError = true;
        this.inputs.company.minItems = 1;
      } else {
        this.inputs.property.hasError = false;
        this.inputs.company.hasError = false;
        this.inputs.company.minItems = 0;
        this.inputs.uprn.minItems = 0;
        this.inputs.uprn.hasError = false;
      }
    },
    companyColumns: function (value) {
      if (this.columns.length !== this.inputs.property.columns.length) {
        const cols = this.propertyColumns.concat(this.companyColumns);
        this.inputs.postcode.columns = this.columns.filter(x => !cols.includes(x));
      }
      if (value.length < this.inputs.company.minItems || value.length > this.inputs.company.maxItems) {
        this.inputs.company.hasError = true;
        this.inputs.property.minItems = 1;
      } else {
        this.inputs.company.hasError = false;
        this.inputs.property.hasError = false;
        this.inputs.property.minItems = 0;
      }
    },
    postcodeColumns: function (value) {
      this.inputs.postcode.hasError = value.length < this.inputs.postcode.minItems || value.length > this.inputs.postcode.maxItems;
    },
    latitudeColumns: function (value) {
      if (value.length < this.inputs.latitude.minItems || value.length > this.inputs.latitude.maxItems) {
        this.inputs.latitude.hasError = true;
        return;
      }
      this.inputs.latitude.hasError = false;

      if (this.inputs.longitude.selectedColumns.length === 0) {
        this.inputs.longitude.hasError = true
      }
    },
    longitudeColumns: function (value) {
      if (value.length < this.inputs.longitude.minItems || value.length > this.inputs.longitude.maxItems) {
        this.inputs.longitude.hasError = true;
        return;
      }
      this.inputs.longitude.hasError = false;

      if (this.inputs.latitude.selectedColumns.length === 0) {
        this.inputs.latitude.hasError = true
      } else {
        this.inputs.property.hasError = false;
        this.inputs.company.hasError = false;
        this.inputs.postcode.hasError = false;
        this.inputs.postcode.minItems = 0;
        this.inputs.company.minItems = 0;
        this.inputs.uprn.minItems = 0;
        this.inputs.uprn.hasError = false;
      }
    },
    selectedUprnColumn(value) {
      if (value === null) {
        return;
      }
      this.inputs.uprn.selectedColumns.push(value);
      this.inputs.uprn.selected = null;
    },
    selectedPropertyColumn(value) {
      if (value === null) {
        return;
      }
      this.inputs.property.selectedColumns.push(value);
      this.inputs.property.selected = null;
    },
    selectedCompanyColumn(value) {
      if (value === null) {
        return;
      }
      this.inputs.company.selectedColumns.push(value);
      this.inputs.company.selected = null;
    },
    selectedPostcodeColumn(value) {
      if (value === null) {
        return;
      }
      this.inputs.postcode.selectedColumns.push(value);
      this.inputs.postcode.selected = null;
    },
    selectedLatitudeColumn(value) {
      if (value === null) {
        return;
      }
      this.inputs.latitude.selectedColumns.push(value);
      this.inputs.latitude.selected = null;

    },
    selectedLongitudeColumn(value) {
      if (value === null) {
        return;
      }
      this.inputs.longitude.selectedColumns.push(value);
      this.inputs.longitude.selected = null;
    }

  },
  computed: {
    availableColumns() {
      let all = [].concat(this.inputs.property.selectedColumns)
      all = all.concat(this.inputs.company.selectedColumns)
      all = all.concat(this.inputs.postcode.selectedColumns)
      all = all.concat(this.inputs.uprn.selectedColumns)
      all = all.concat(this.inputs.latitude.selectedColumns)
      all = all.concat(this.inputs.longitude.selectedColumns)
      return this.columns.filter(x => !all.includes(x));
    },
    uprnColumns() {
      return this.inputs.uprn.selectedColumns
    },
    propertyColumns() {
      return this.inputs.property.selectedColumns
    },
    companyColumns() {
      return this.inputs.company.selectedColumns
    },
    postcodeColumns() {
      return this.inputs.postcode.selectedColumns
    },
    latitudeColumns() {
      return this.inputs.latitude.selectedColumns
    },
    longitudeColumns() {
      return this.inputs.longitude.selectedColumns
    },
    hasError() {
      return this.inputs.property.hasError
          || this.inputs.company.hasError
          || this.inputs.postcode.hasError
          || this.inputs.uprn.hasError
          || this.inputs.latitude.hasError
          || this.inputs.longitude.hasError;
    },
    hideFooter() {
      return this.hFooter || this.hasError;
    },
    selectedPropertyColumn() {
      return this.inputs.property.selected;
    },
    selectedCompanyColumn() {
      return this.inputs.company.selected;
    },
    selectedPostcodeColumn() {
      return this.inputs.postcode.selected;
    },
    selectedUprnColumn() {
      return this.inputs.uprn.selected;
    },
    selectedLatitudeColumn() {
      return this.inputs.latitude.selected;
    },
    selectedLongitudeColumn() {
      return this.inputs.longitude.selected;
    }

  },
  data() {
    return {
      selectedAddressIndex: -1,
      currentRow: 0,
      selectedMatchUprn: null,
      showNextButton: false,
      showSkipButton: false,
      postcodeAddresses: [],
      unmatchedPostcode: null,
      unmatchedFields: [],
      unmatchedLine: [],
      showAllFields: false,
      currentUnMatch: 0,
      matchingFile: null,
      columns: [],
      hFooter: true,
      isHeader: 0,
      includeLatLng: 0,
      includeProviderData: 0,
      includeNetworkCoverageData: 0,
      condenseAddresses: 1,
      showOverlay: false,
      inputs: {
        uprn: {
          name: "UPRN",
          columns: [],
          selectedColumns: [],
          hasError: false,
          minItems: 0,
          maxItems: 1,
          selected: null,
        },
        property: {
          name: "Property Name / Number / Street",
          columns: [],
          selectedColumns: [],
          hasError: true,
          minItems: 1,
          maxItems: this.columns,
          selected: null,
        },
        company: {
          name: "Company Name",
          columns: [],
          selectedColumns: [],
          hasError: false,
          minItems: 1,
          maxItems: 1,
          selected: null,
        },
        postcode: {
          name: "Postcode",
          columns: [],
          selectedColumns: [],
          hasError: true,
          minItems: 1,
          maxItems: 1,
          selected: null,
        },
        latitude: {
          name: "Latitude",
          columns: [],
          selectedColumns: [],
          hasError: false,
          minItems: 1,
          maxItems: 1,
          selected: null,
        },
        longitude: {
          name: "Longitude",
          columns: [],
          selectedColumns: [],
          hasError: false,
          minItems: 1,
          maxItems: 1,
          selected: null,
        }
      },
      requestList: [],
      fields: [
        {key: 'date', sortable: false},
        {key: 'fileName', sortable: false},
        {key: 'rows_count', sortable: false},
        {key: 'proceed', sortable: false, label: 'Processed'},
        {key: 'skipped', sortable: false, label: 'Skipped'},
        {key: 'succeed', sortable: false, label: 'Matched'},
        {key: 'status', sortable: false},
        {key: 'action', sortable: false},
        {key: 'manual', sortable: false},
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
    }
  },
  methods: {
    uploadMatchingFile(e) {
      e.preventDefault()
      if (this.hasError) {
        this.$swal("Property Name / Number / Street and Postcode must have at least one field mapped to them.");
        if (this.inputs.property.selectedColumns.length === 0) {
          this.inputs.property.hasError = 1;
        }
        if (this.inputs.postcode.selectedColumns.length === 0) {
          this.inputs.postcode.hasError = 1;
        }
        return;
      }
      const self = this;
      this.showOverlay = true;
      let formData = new FormData();
      formData.append('csv', this.matchingFile);
      formData.append('is_header', this.isHeader);
      formData.append('include_latlng', this.includeLatLng);
      formData.append('include_provider_data', this.includeProviderData);
      formData.append('include_mobile_coverage_data', this.includeNetworkCoverageData);
      formData.append('uprn_columns', this.inputs.uprn.selectedColumns);
      formData.append('property_columns', this.inputs.property.selectedColumns);
      formData.append('company_columns', this.inputs.company.selectedColumns);
      formData.append('postcode_columns', this.inputs.postcode.selectedColumns);
      formData.append('condense_addresses', this.condenseAddresses);
      formData.append('latitude_columns', this.inputs.latitude.selectedColumns);
      formData.append('longitude_columns', this.inputs.longitude.selectedColumns);

      axios.post(process.env.VUE_APP_API_URL + "/api/addressmatch", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(() => {
        self.$swal("Uploaded", "You will get email notification when match finished", "success").then(() => {
          self.showOverlay = false;
          self.$bvModal.hide('address_matching');
          self.modalClosed();
        });

      }).catch(() => {
        self.showOverlay = false;
      }).finally(() => {
        self.refreshMatchingTable();
      })
    },
    modalClosed() {
      this.matchingFile = null;
      this.columns = [];
      this.isHeader = 0;
      this.includeLatLng = 0;
      this.includeProviderData = 0;
      this.inputs.property.selectedColumns = [];
      this.inputs.company.selectedColumns = [];
      this.inputs.postcode.selectedColumns = [];
      this.inputs.uprn.selectedColumns = [];
      this.inputs.latitude.selectedColumns = [];
      this.inputs.longitude.selectedColumns = [];
    },
    tagValidator(tag) {
      return this.columns.findIndex(key => key === tag) !== -1;
    },
    getAddressMatchRequestList() {
      const self = this;
      self.showOverlay = true;
      axios.get(process.env.VUE_APP_API_URL + '/api/addressmatch').then(resp => {
        self.showOverlay = false;
        self.requestList = resp.data.data;
      }).catch(() => {
        self.showOverlay = false;
      });
    },
    myProvider(ctx, callback) {
      const self = this;
      const params = '?page=' + ctx.currentPage + '&size=' + ctx.perPage
      axios.get(process.env.VUE_APP_API_URL + '/api/addressmatch' + params).then(resp => {
        self.showOverlay = false;
        self.requestList = resp.data;
        self.totalRows = resp.data.length;
        callback(self.requestList)

      }).catch(() => {
        self.showOverlay = false;
        callback([])
      });
      return null
    },
    refreshMatchingTable() {
      this.$root.$emit('bv::refresh::table', 'my-table')

    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    sourceDownloadLink(item) {
      return process.env.VUE_APP_API_URL + '/addressmatch/' + item.id + '/download/source/' + item.secret;
    },
    resultDownloadLink(item) {
      return process.env.VUE_APP_API_URL + '/addressmatch/' + item.id + '/download/result/' + item.secret;
    },
    getUnmatched(id, allFields = false, skipped = false) {
      this.currentUnMatch = id;
      this.showAllFields = allFields;
      const self = this;
      axios.get(process.env.VUE_APP_API_URL + '/api/addressmatch/unmatched/' + id + '/' + allFields + '/' + skipped).then(resp => {
        if (resp.data.error !== undefined && resp.data.error == 'All Records Matched') {
          self.$swal("Done", "All Records Matched");
          self.$bvModal.hide("address_unmatched");
          self.refreshMatchingTable();
          return;
        }

        self.showNextButton = false;
        self.showSkipButton = true;
        self.unmatchedLine = resp.data.line;
        self.unmatchedFields = resp.data.fields;
        self.unmatchedPostcode = resp.data.postcode;
        self.postcodeAddresses = resp.data.postcodes;
        self.currentRow = resp.data.row;
        this.$bvModal.show("address_unmatched");
      });
    },
    getPostCode(postcode) {
      this.unmatchedPostcode = postcode;
      const self = this;
      axios.post(process.env.VUE_APP_API_URL + '/api/addressmatch/unmatched/postcode', {postcode: postcode}).then(resp => {
        self.postcodeAddresses = resp.data.data;
      });
    },
    setUprn(uprn, selectedAddressIndex) {
      if (uprn !== 0) {
        this.showNextButton = true;
        this.showSkipButton = false;
      }
      this.selectedAddressIndex = selectedAddressIndex;
      const self = this;
      axios.get(process.env.VUE_APP_API_URL + '/api/addressmatch/match/' + this.currentUnMatch + '/' + this.currentRow + '/' + uprn).then(resp => {
        if (resp.data.error !== undefined && resp.data.error == 'All Records Matched') {
          self.$swal("Done", "All Records Matched");
          self.$bvModal.hide("address_unmatched");

          return;
        }
        if (uprn === 0) {
          self.showNextButton = false;
          self.getUnmatched(self.currentUnMatch, self.showAllFields, false);
        }
        self.refreshMatchingTable();

      })
    },
    skip() {
      this.setUprn(0, -1);
    },
    next() {
      this.getUnmatched(this.currentUnMatch, this.showAllFields);
      this.showNextButton = false;
      this.selectedAddressIndex = -1;
    },
    deleteRecord(item) {
      const self = this;
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure you wish to delete?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        cancelButtonText: 'No, Keep it!',
        showCloseButton: true,
        showLoaderOnConfirm: true
      }).then((result) => {
        if (result.value) {
          axios.delete(process.env.VUE_APP_API_URL + '/api/addressmatch/' + item.id).then(() => {
            self.refreshMatchingTable();
          });
        }
      })

    },
  }
}
</script>

<style>
#advanced_search {
  z-index: 99999;
}

.b-form-tag-remove {
  position: relative !important;
  margin-left: 10px !important;
}

table {
  font-size: 12px;
}

.postcode_addresses,
#unmatched_table {
  max-height: 400px;
  overflow: auto;
  padding-left: 5px;

}

.postcode_addresses li {
  list-style: none;
  font-size: 12px;
  cursor: pointer;
  padding: 2px;
}

.postcode_addresses li:hover {
  background-color: #cccccc;
}

.address_list_item_active {
  background-color: #cccccc;
}
</style>
