<template>
  <b-modal id="splitter_info" :title="title" size="xl">
    <b-overlay :show="loading">
      <div class="row" v-if="objectModalInfo && objectModalInfo.code === 'OT_SPLITTER' && splitterInfo">
        <div class="col-6">
          <table class="table table-striped h-100">
            <thead>
            <tr>
              <th>Input</th>
            </tr>
            </thead>
            <tbody>

            <tr>
              <td v-if="splitterInfo.input.fibre" class="align-middle cursor-pointer">
                <div class="d-flex flex-row justify-content-between">
                  <FibreTubeBadge :fibre="splitterInfo.input.fibre"/>
                  <button class="btn btn-danger btn-sm" @click="removeFibreConnection('input', 1)">Disconnect</button>
                </div>
              </td>
              <td v-else :rowspan="splitterInfo.outputs.length" class="align-middle text-center">
                <button class="btn-sm btn btn-success" @click="getFibres('input', 1)"><small>Add Input Fibre</small>
                </button>
              </td>

            </tr>
            </tbody>
          </table>
        </div>
        <div class="col-6">
          <table class="table table-striped">
            <thead>
            <tr>
              <th>Output</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="output in splitterInfo.outputs">
              <td>
                <div>
                  <button v-if="!output.fibre" class="btn btn-sm btn-success"
                          @click="getFibres('output',output.port_index)"><small>Add Output Fibre to Port
                    {{ output.port_index }}</small>
                  </button>
                  <div v-else class="d-flex flex-row justify-content-between">
                    <FibreTubeBadge :fibre="output.fibre"/>
                    <button class="btn btn-danger btn-sm" @click="removeFibreConnection('output', output.port_index)">
                      Disconnect
                    </button>
                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-overlay>
  </b-modal>
</template>
<script>
import ObjectTypesEnum from "@/Enums/ObjectTypesEnum";
import {mapGetters, mapMutations} from "vuex";
import Objects from "@/Api/Objects";
import FibreTubeBadge from "@/components/UI/FibreTubeBadge.vue";
import ObjectTypes from "@/Api/ObjectTypes";

export default {
  name: "Splitter",
  components: {FibreTubeBadge},
  computed: {
    ...mapGetters({
      splitterId: 'ObjectInfo/objectId',
      objectCode: 'ObjectInfo/objectCode',
      objectModalInfo: 'ObjectInfo/objectModalInfo',
    }),
    title() {
      if (this.splitterInfo && this.splitterInfo.object_type !== undefined) {
        return this.splitterInfo.generated_identifier + ' (' + this.splitterInfo.object_type.name + ')';
      }
      return '';
    },
  },
  watch: {
    objectModalInfo(value) {
      if (value && value.code === ObjectTypesEnum.OT_SPLITTER) {
        this.viewSplitter();
      }
    }
  },
  data() {
    return {
      loading: false,
      splitterInfo: null,
    }
  },
  methods: {
    ...mapMutations({
      setFibreId: "Tracing/setFibreId",
    }),
    showTracing(item) {
      this.setFibreId(item.id);
    },
    viewSplitter(item) {
      this.loading = true;
      ObjectTypes.getObjectTypeInfo(ObjectTypesEnum.OT_SPLITTER, this.objectModalInfo.id).then(response => {
        this.splitterInfo = response.data;
        const modalId = ObjectTypesEnum.getModalId(ObjectTypesEnum.OT_SPLITTER);
        this.$bvModal.show(modalId);
      }).finally(() => {
        this.loading = false;

      });
    },

    getFibres(type, index) {
      this.loading = true;
      Objects.freeFibresForSplitter(this.splitterInfo.id).then(response => {
        if (response.data.data.length === 0) {
          this.$swal('', 'No available fibre for this object', 'info');
          this.loading = false;
          return;
        }
        this.$selectSingleFibre({
          title: 'Select Fibre',
          fibres: response.data.data,
          meth: (fibre) => {
            this.setFibre(fibre, type, index)
          }
        })

      }).finally(() => {
        this.loading = false;
      })

    },
    setFibre(fibre, type, index) {
      this.loading = true;
      if (type === 'input') {
        axios.put(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/ot_splitter/' + this.splitterInfo.id + '/input/' + fibre).then(() => {
          this.viewSplitter({id: this.splitterInfo.id});
        }).finally(() => {
          this.loading = false;
        })
      } else if (type === 'output') {
        axios.put(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/ot_splitter/' + this.splitterInfo.id + '/output/' + fibre + '/' + index).then(() => {
          this.viewSplitter({id: this.splitterInfo.id});
        }).finally(() => {
          this.loading = false;
        })
      } else {
        console.log('Not Implemented');
      }
    },
    removeFibreConnection(type, index) {
      this.loading = true;
      if (type === 'input') {
        axios.delete(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/ot_splitter/' + this.splitterInfo.id + '/input').then(() => {
          this.viewSplitter({id: this.splitterInfo.id});
        }).finally(() => {
          this.loading = false
        });
      } else if (type === 'output') {
        axios.delete(process.env.VUE_APP_NETWORKING_API_URL + '/api/object/ot_splitter/' + this.splitterInfo.id + '/output/' + index).then(() => {
          this.viewSplitter({id: this.splitterId});
        }).finally(() => {
          this.loading = false;
        })
      } else {
        console.log('Not Implemented');
      }
    }
  }
}
</script>
<style scoped>

</style>