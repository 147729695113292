import { render, staticRenderFns } from "./SplicingDomeInfo.vue?vue&type=template&id=228e461b&scoped=true"
import script from "./SplicingDomeInfo.vue?vue&type=script&lang=js"
export * from "./SplicingDomeInfo.vue?vue&type=script&lang=js"
import style0 from "./SplicingDomeInfo.vue?vue&type=style&index=0&id=228e461b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "228e461b",
  null
  
)

export default component.exports