<template>
  <b-modal @ok="getPropertyList()" id="property_list" ok-title="Export" title="Property List">
    <b-form-group label="Postcodes">
      <b-form-textarea rows="15" name="textarea" placeholder="Please enter postcodes, one per line."
                       v-model="postcodes">
      </b-form-textarea>
    </b-form-group>
    <b-form-group class="mt-3">
      <b-form-checkbox id="checkbox-1" v-model="condenseAddresses" name="checkbox-2" value="1" unchecked-value="0"
                       switch>
        Condense Addresses
      </b-form-checkbox>
      <b-form-checkbox id="checkbox-2" v-model="includeProvidersData" name="checkbox-include-providers" value="1"
                       unchecked-value="0" switch>
        Include Providers Data
      </b-form-checkbox>
      <b-form-checkbox id="checkbox-3" v-model="includeCoveragesData" name="checkbox-include-coverages-data" value="1"
                       unchecked-value="0" switch>
        Include Mobile Coverage Data
      </b-form-checkbox>
    </b-form-group>
  </b-modal>
</template>

<script>
import axios from "axios";

export default {
  name: "PropertyList",
  data() {
    return {
      postcodesArray: [],
      postcodes: null,
      data: [],
      condenseAddresses: false,
      includeProvidersData: false,
      includeCoveragesData: false,
    }
  },
  methods: {
    getHash() {
      if (this.validatePostcode()) {
        return axios.post(process.env.VUE_APP_API_URL + '/api/property_list/getCsvLink', this.postcodesArray);
      }
    },
    getPropertyList() {
      if (this.postcodes.length <= 0) {
        return
      }
      this.$root.$emit('showLoader', "Loading Property List")
      const self = this
      this.getHash().then(response => {
        if (self.postcodes.length > 0 && self.validatePostcode()) {
          axios.post(process.env.VUE_APP_API_URL + '/api/property_list', {
            postcodes: self.postcodesArray,
            condense: self.condenseAddresses,
            include_providers_data: self.includeProvidersData,
            include_network_coverage_data: self.includeCoveragesData,
            hash: response.data.hash
          }).then(() => {
            this.$root.$emit('hideLoader')
            this.postcodes = null;
            window.open(process.env.VUE_APP_ASSETS_URL + '/postcodes/' + response.data.hash + '.csv', '_blank')
          }).catch(error => {
            this.$root.$emit('hideLoader')
            this.postcodes = null;
            console.log(error);
          });
        }
      })
    },
    validatePostcode() {
      const regexp = new RegExp(process.env.VUE_APP_POSTCODE_PATTERN);
      this.postcodesArray = this.postcodes.split("\n");
      const self = this
      this.postcodesArray.map(function (value, index) {
        if (!regexp.test(value)) {
          self.postcodesArray.splice(index, 1);
        }
      })
      if (this.postcodesArray.length > 0) {
        return true
      } else {
        this.$root.$emit('hideLoader')
        this.postcodes = null;
        self.$swal("Error", "No valid postcodes found in the list", "error");
        return false
      }
    }
  }
}
</script>

<style scoped></style>
